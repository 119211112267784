<template>
  <div>
    <vs-popup class="sm:popup-w-50" title="Tambah Group Asset" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Kode Group *</label>
            <vs-input class="w-full" v-model="data.kode" placeholder="Generate Otomatis" disabled/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Nama Group *</label>
            <vs-input class="w-full" v-model="data.nama" />
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Account Debit *</label>
            <div @click="chooseCoa('DEBET')">
              <vx-input-group>
                <vs-input :value="data.nama_coa_debet" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Account Credit *</label>
            <div @click="chooseCoa('KREDIT')">
              <vx-input-group>
                <vs-input :value="data.nama_coa_kredit" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
        </div>

        <div class="vx-row mt-6">
          <div class="vx-col w-full">
            <div class="flex justify-end w-full">
              <div class="flex">
                <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
                <vs-button :disabled="isLoading" @click="save">
                  <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
                  <span v-if="!isLoading">Simpan</span>
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--modal coa-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Account"
                :active="modalCoa.active"
                v-on:update:active="modalCoa.active = $event">
        <Coa :selectable="true" :externalFilter="filterCoa" @selected="onSelectedModalCoa"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import SetupFixedAssetRepository from '@/repositories/accounting/setup-fixed-asset-repository'
import Coa from '@/views/pages/master/coa/Coa'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import _ from 'lodash'

export default {
  name: 'GroupAssetAdd',
  props: ['isActive'],
  components: {
    ValidationErrors,
    Coa
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      modalCoa: {
        active: false
      },
      data: {}
    }
  },
  computed: {
    dataHeaderSection () {
      return this.$store.state.accounting.setupFixedAsset.headerSection
    },
    filterCoa () {
      return {
        tipe: 'DETAIL',
        id_proyek: this.dataHeaderSection.filter.id_proyek
      }
    }
  },
  methods: {
    save () {
      this.errors = null
      this.isLoading = true

      const params = this.buildParams()
      SetupFixedAssetRepository.createGroup(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    buildParams () {
      return {
        kode: this.data.kode,
        nama: this.data.nama,
        id_coa_debet: this.data.id_coa_debet,
        id_coa_kredit: this.data.id_coa_kredit
      }
    },

    chooseCoa (type) {
      this.modalCoa.type = type
      this.modalCoa.active = true
    },

    onSelectedModalCoa (data) {
      if (this.modalCoa.type === 'DEBET') {
        this.data.id_coa_debet = data.id
        this.data.nama_coa_debet = data.kode + ' - ' + data.nama
      } else {
        this.data.id_coa_kredit = data.id
        this.data.nama_coa_kredit = data.kode + ' - ' + data.nama
      }
      this.modalCoa.active = false
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
